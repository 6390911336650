x-input-adornment
  display: inline-flex
  font-size: 0
  line-height: 0
  &[position="start"]
    margin-right: 1px
    margin-left: 15px
  &[position="end"]
    margin-right: 15px
    margin-left: 1px
